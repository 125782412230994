<template>
  <base-section
    id="features"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          icon: 'mdi-keyboard-outline',
          title: 'Trendy Designs',
          text: 'Creating \'Aha\' moments with our designs. We take care of the basic along with adding magic.',
        },
        {
          icon: 'mdi-camera-outline',
          title: 'World-Class',
          text: 'Producing the high standard designs matching with highly paid studios.',
        },
        {
          icon: 'mdi-pencil-outline',
          title: 'Brand Making',
          text: 'Promoting Brand images with intuitive designs and complete management of social media accounts.',
        },
        {
          icon: 'mdi-puzzle-outline',
          title: 'Personal Attention',
          text: 'Supporting your needs and questions anytime with dedicated team who closely work with you to ensure remediation.',
        },
      ],
    }),
  }
</script>
